//运单相关 路由
export default [

	//业务线相关
	{
		path:'business_line/bl_creat',
		component(){
			return import('@/pages/real_time/business_line/bl_creat.vue')
		}
	},{
		path:'business_line/bl_edit',
		component(){
			return import('@/pages/real_time/business_line/bl_edit.vue')
		}
	},{
		path:'business_line/bl_list',
		component(){
			return import('@/pages/real_time/business_line/bl_list.vue')
		}
	},


	//货源相关
	{
		path:'tord_real_time/cargo_creat',
		component(){
			return import('@/pages/real_time/cargo/cargo_creat.vue')
		}
	},{
		path:'tord_real_time/cargo_edit',
		component(){
			return import('@/pages/real_time/cargo/cargo_edit.vue')
		}
	},{
		path:'tord_real_time/cargo_list',
		component(){
			return import('@/pages/real_time/cargo/cargo_list.vue')
		}
	},
	
	
	//财务管理
	{
		path:'tord_real_time/tord_pay_list',
		component(){
			return import('@/pages/real_time/finance/tord_pay_list.vue')
		}
	},
	/* 支付计划 */
	{
		path:'tord_real_time/tord_wait_list',	//
		component(){
			return import('@/pages/real_time/finance/tord_wait_list.vue')
		}
	},
	{
		path:'tord_real_time/tord_wait_list_plan',	//优通专用
		component(){
			return import('@/pages/real_time/finance_plan/tord_wait_list_plan.vue')
		}
	},
	// {
	// 	path:'tord_real_time/tord_pay_end',
	// 	component(){
	// 		return import('@/pages/real_time/finance_plan/tord_pay_end.vue')
	// 	}
	// },
	{
		path:'tord_real_time/tord_invoice',
		component(){
			return import('@/pages/real_time/finance_plan/tord_invoice.vue')
		}
	},
	{
		path:'tord_real_time/tord_invoice_list',
		component(){
			// return import('@/pages/real_time/finance/tord_invoice_list/index.vue')
			return import('@/pages/real_time/finance/tord_invoice_list.vue')
		}
	},

	//开票相关
	{
		path:'tord_real_time/tord_invoice_start',
		component(){
			// return import('@/pages/real_time/finance/tord_invoice_list/index.vue')
			return import('@/pages/real_time/invoice/invoice_start.vue')
		}
	},
	{
		path:'tord_real_time/tord_invoice_in',
		component(){
			// return import('@/pages/real_time/finance/tord_invoice_list/index.vue')
			return import('@/pages/real_time/invoice/invoice_in.vue')
		}
	},
	{
		path:'tord_real_time/tord_invoice_end',
		component(){
			// return import('@/pages/real_time/finance/tord_invoice_list/index.vue')
			return import('@/pages/real_time/invoice/invoice_end.vue')
		}
	},
	//运单相关
	{
		path:'tord_real_time/tord_deal',
		component(){
			return import('@/pages/real_time/tord/deal.vue')
		}
	},
	{
		path:'tord_real_time/tord_batch_edit',
		component(){
			return import('@/pages/real_time/tord/tord_batch_edit.vue')
		}
	},
	{
		path:'tord_real_time/excle_send_goods',
		component(){
			return import('@/pages/real_time/tord/excel_send_goods.vue')
		}
	},
	{
		path:'tord_real_time/batch_tord_edit',
		component(){
			return import('@/pages/real_time/tord/batch_tord_edit.vue')
		}
	},{
		path:'tord_real_time/tord_act',
		component(){
			return import('@/pages/real_time/tord/act.vue')
		}
	},
	{
		path:'tord_real_time/tord_pay_start',
		component(){
			return import('@/pages/real_time/tord/tord_pay_start.vue')
		}
	},
	{
		path:'tord_real_time/tord_pay_end',
		component(){
			return import('@/pages/real_time/tord/tord_pay_end.vue')
		}
	},
	{
		path:'tord_real_time/tord_transport_act',
		component(){
			return import('@/pages/real_time/tord/act_transport.vue')
		}
	},{
		path:'tord_real_time/act_end',
		component(){
			return import('@/pages/real_time/tord/act_end.vue')
		}
	},{
		path:'tord_real_time/tord_end',
		component(){
			return import('@/pages/real_time/tord/end.vue')
		}
	},
]